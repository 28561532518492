<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nueva Suficiencia</h1>
        <hr class="">
      </v-col>
      <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
        <v-row class="mb-4">
          <v-expansion-panels inset>
            <v-expansion-panel>
              <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <FileInput 
                    :tenderId="data.tenderId"
                    :formatosId="data.formatosId"
                    :file="data.suficiencia_url"
                    @getImageFile="handleImageFile"
                    @changeShowImage="handleShowImage"
                  />
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-col cols="12" class="mt-4">
            <v-btn type="submit" color="primary" style="width: 200px">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import FileInput from "../../components/FileInput";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      data: {
        tenderId: null,
        formatosId: null,
        suficiencia_url: '',
        
      },
    };
  },
  components: {
    FileInput,
    Overlay,
  },
  created() {
    this.data.tenderId = parseInt(this.$route.params.id);
    this.data.formatosId = parseInt(this.$route.params.formatId);

  },
  methods: {
    ...mapActions('sufficiency', ['createSufficiency']),
    handleImageFile(file) {
      this.data.suficiencia_url = file;
    },
    handleShowImage(show) {
    },
    async validateRequired(){
      if (!this.data.suficiencia_url){
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      
      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createSufficiency(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
